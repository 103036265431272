/**
 * Simple alternative to window.location that also works on the server-side with Router.asPath
 */
export function parseAsPath(asPath: string): {
  pathname: string
  hash: string | undefined
  search: string | undefined
} {
  const [withoutHash = '', hash] = asPath.split('#')
  const [pathname = '', search] = withoutHash.split('?')
  return { pathname, search, hash: hash ? '#' + hash : hash }
}
